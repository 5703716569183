import React, { useState } from "react"
import { Link } from "gatsby";

const MainMenu = (props) => {
const [showDropDown,setShowDropDown] = useState(-1);
  return (
    <div className="menu">
        <nav className="navigation-menu">
        {
            props.menu_data && props.menu_data.length > 0 && 
            <ul className="menu-list">
                {
                Object.keys(props.menu_data).filter(key => props.menu_data[key].Show_Header_Menu === true).map((key, index) => {

                    var get_submenu = Object.keys(props.menu_data).filter(key2 => (props.menu_data[key2].Main_Parent !== null && props.menu_data[key2].Main_Parent.id === props.menu_data[key].id));
                    return(
                    <li key={index}>
                        {
                            props.menu_data[key].Link_Type === "Megamenu" ? 
                                <a href="javascript:void(0);" onClick={()=>{props.openSearch(props.menu_data[key].Label)}}>{props.menu_data[key].Label}</a>
                            : props.menu_data[key].Link_Type === "Dropdown_menu" ? 
                                props.menu_data[key].Label === "Contact" ?
                                    <Link to={`/${props.menu_data[key].Secondary_URL ? props.menu_data[key].Secondary_URL+'/' : props.menu_data[key].Primary_URL}/`}>{props.menu_data[key].Label}</Link>
                                :
                                    <a href="javascript:void(0);" onClick={() => setShowDropDown(props.menu_data[key].id)}>{props.menu_data[key].Label}</a>
                            : <Link to={`/${props.menu_data[key].Secondary_URL ? props.menu_data[key].Secondary_URL+'/' : props.menu_data[key].Primary_URL}/`}>{props.menu_data[key].Label}</Link>
                        }      

                        {
                            get_submenu.length > 0 && 
                            <div className={`dropdown-link ${showDropDown === props.menu_data[key].id ? "show_mobile_menu" : ""}`}>
                                <ul className={`submenu_list ${props.menu_data[key].Primary_URL}`}>
                                {
                                    get_submenu.map((key2, i) => {
                                        return(
                                            <li key={i}><Link to={props.menu_data[key2].Secondary_URL ? props.menu_data[key2].Secondary_URL+'/' : `/${props.menu_data[key].Primary_URL}/${props.menu_data[key2].Primary_URL}/`}>{props.menu_data[key2].Label}</Link></li>
                                        )
                                    })
                                }
                                </ul> 
                            </div>                                
                        }
                                         
                    </li>
                    
                    )
                })
                }
            </ul>
        }                    
        </nav>
        <a href="tel:020 8520 9911" className="d-lg-none phone_number" ><i className="icon-phone"></i></a>
        <strong onClick={()=>{props.openSearch("Site Search")}} className="search-menu">
            <i className={`icon-search-white ${props.search ? " open" : ""}`}></i>
        </strong>

        <button onClick={props.openHam} className={props.open ? "hamburger d-lg-none close" : "hamburger d-lg-none"}>
            <span></span>
            <span></span>
            <span></span>
        </button>
    </div>
  );
};
export default MainMenu;
