/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header  from './Header/Header'
import Footer  from './Footer/Footer'
import PopupMenu from "../components/Header/Sections/PopupMenu";
import { useLocation } from "@reach/router"
import FloatingNumber from "./Auctions/FloatingNumber/FloatingNumber"
const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query siteLayout {  
      glstrapi{

        siteConfig {
          Facebook_Link
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Footer_Bottom_Links {
            Label
            Menu_Link {
              Primary_URL
            }
          }
          Footer_Logo_Component {
            Link
            Name
            Image {
              url
              alternativeText
            }        
          }
        }

        allMenus(sort: "Sort_Order:ASC"){
          id
          Primary_URL
          Secondary_URL
          Label
          Show_Header_Menu
          Show_Footer_Menu
          Show_Popup_Menu
          Sort_Order
          Footer_Phone_Number
          Link_Type
  
          Main_Parent {
            Label
            Primary_URL
            Secondary_URL
            id
          }
          
          Secondary_Menu {
            Primary_URL
            Secondary_URL
            id
            Label
          }
        }

      }
    }
  `)
  var siteConfig = data.glstrapi?.siteConfig;
  var site_menu = data.glstrapi?.allMenus;
  const [state, setState] = React.useState({
    showMenu: false
  })
  const handlerClick = () => {
      setState({ ...state, showMenu: !state.showMenu })
      if (state.showMenu == true) {
          document.body.className = '';
      }
      else {
          document.body.classList.add("overflow-hidden");
      }
  }
  useEffect(()=>{
    window.scrollTo(0,0);
    checkFunction()
  },[])
  const checkFunction = (data) => {
    switch(data) {
      case '/auction-commercial-property/for-sale/in-uk/':
        return true;        
      case '/auction-residential-property/for-sale/in-uk/':
        return true;        
      case '/auction-property/for-sale/in-uk/':
        return true;
      default:
        return false;
    }
  }
  const path = useLocation();
  return (
    <div className={`wrapper ${props.classNames} ${props.GQLPage?.Layout} ${props?.GQLPage?.Alias}`}>
        {
          props.GQLPage?.Layout === "Without_Banner" ? 
            <div className={`search-header  details-header ${props?.GQLPage?.Alias === "current-catalogue" || props?.GQLPage?.Alias === "available-lots" ? "search-property-header" : ""}`}>
              <Header handlerClick={handlerClick} site_menu={site_menu} GQLPage={props?.GQLPage}/>
            </div>
          : 
            <Header handlerClick={handlerClick} site_menu={site_menu} GQLPage={props?.GQLPage} Alias={props?.GQLPage?.Alias}/>
        }
        <main className={`${props?.GQLPage?.Alias === "current-catalogue" || props?.GQLPage?.Alias === "available-lots" ? "search-header search-property-header" : ""}`}>
          {props.children}
          {checkFunction(path.pathname) == true && <FloatingNumber Module={{Publish: true, Phone_Number: "020 7637 4000"}}/>}
        </main>
        <Footer {...props} site_config={siteConfig} site_menu={site_menu} popular_search_slug={props.popular_search_slug } /> 
      {
        props.search && <PopupMenu closeSearch={props.closeSearch} handlerClick={props.handlerClick} menu_heading={props.menu_heading} search={props.search} open={props.open} menu_data={site_menu} />
      }
    </div>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
